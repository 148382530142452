import React, { FunctionComponent, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { Form } from 'react-final-form';
import { z } from 'zod';
import { getValidator, moZ } from '@src/utils/moZod';
import { ActorTypeEnum } from '@src/lookup';
import { DateTime } from 'luxon';
import { InfoOutlined } from '@mui/icons-material';
import books from './books.svg';
import paused from './paused.svg';
import { MoSpacing } from '../MoSpacing';
import { MoCheckboxField } from '../MoCheckboxField';
import { MoButton } from '../MoButton';
import { MoDatePickerField } from '../MoDatePickerField';
import { MoModal } from '../MoModal';
import { MoSubmitButton } from '../MoSubmitButton';
import { MoTextInputField } from '../MoTextInputField';
import { MoPersistentTooltip } from '../MoPersistentTooltip';

const INITIAL_STEP = 1;
const SUBMIT_STEP = 2;
const SUCCESS_STEP = 3;

export const formSchema = moZ
  .object({
    hasResumesOnDate: moZ.boolean().optional(),
    supervisionResumesOn: moZ.dateTime().optional(),
    supervisionPausedReasonText: moZ.string().optional(),
  })
  .superRefine((data, ctx) => {
    if (data.hasResumesOnDate && !data.supervisionResumesOn) {
      ctx.addIssue({
        path: ['supervisionResumesOn'],
        code: 'custom',
        message: 'Select a date to automatically resume supervision on or uncheck the box.',
      });
    }
  });

export type FormValues = z.infer<typeof formSchema>;

export type PauseSupervisionModalProps = {
  userFirstName?: string;
  viewerActorType: ActorTypeEnum;
  supervisorsText: string;
  pauseSupervision: (values: FormValues) => Promise<{ success: boolean }>;
  onClose: () => void;
  disabled: boolean;
};

const PauseSupervisionModal: FunctionComponent<PauseSupervisionModalProps> = ({
  viewerActorType,
  userFirstName,
  supervisorsText,
  pauseSupervision,
  onClose,
  disabled,
}) => {
  const [step, setStep] = useState(INITIAL_STEP);

  const handleSubmit = async (values: FormValues) => {
    if (step === SUBMIT_STEP) {
      const { success } = await pauseSupervision(values);
      setStep(success ? SUCCESS_STEP : step);
    } else if (step === SUCCESS_STEP) {
      onClose();
    } else {
      setStep(step + 1);
    }
  };

  const viewerIsUser = viewerActorType === ActorTypeEnum.USER;

  const getSuccessText = (resumesOnDate: DateTime | undefined) => {
    const resumesOnDateText = resumesOnDate ? (
      <>
        <Typography>
          {viewerIsUser
            ? `Your supervision will automatically resume on`
            : `${userFirstName}’s supervision will automatically resume on`}{' '}
          <strong>{resumesOnDate.toLocaleString(DateTime.DATE_HUGE)}</strong>.
        </Typography>
        <Typography>
          {viewerIsUser
            ? `If you return before then, you may
        manually resume your supervision at any time from your`
            : `They can manually resume their supervision at any time from their`}{' '}
          <strong>Calendar settings page</strong>.
        </Typography>
      </>
    ) : (
      <Typography>
        {viewerIsUser
          ? 'When you are ready to come back, resume your supervision from your'
          : `When ${userFirstName} is ready to come back, they can resume their supervision from their`}{' '}
        <strong>Calendar settings page</strong>.
      </Typography>
    );

    switch (viewerActorType) {
      case ActorTypeEnum.EMPLOYER_ADMIN:
        return (
          <MoSpacing y={2}>
            {resumesOnDateText}
            <Typography>
              Alternatively, you can manually resume their supervision by going to your{' '}
              <strong>Paused</strong> tab and selecting <strong>Resume supervision</strong>.
            </Typography>
          </MoSpacing>
        );

      case ActorTypeEnum.ADMIN:
        return (
          <MoSpacing y={2}>
            {resumesOnDateText}
            <Typography>
              Alternatively, you can manually resume their supervision from either the{' '}
              <strong>Actions</strong> tab of their profile or from the <strong>Supervisees</strong>{' '}
              tab of their employer page.
            </Typography>
          </MoSpacing>
        );

      default:
        return resumesOnDateText;
    }
  };

  return (
    <MoModal open onClose={disabled ? () => {} : onClose}>
      <Box display="flex" justifyContent="center">
        <img src={step !== SUCCESS_STEP ? books : paused} alt="" width="100%" />
      </Box>

      <Form<FormValues>
        onSubmit={handleSubmit}
        validate={getValidator(formSchema)}
        render={({ handleSubmit, values, form }) => {
          return (
            <form onSubmit={handleSubmit}>
              <MoSpacing y={2}>
                {/* MODAL HEADINGS AND TEXT */}
                {step === INITIAL_STEP && (
                  <>
                    <Typography variant="h3">
                      Do you want to pause {viewerIsUser ? 'your' : `${userFirstName}’s`}{' '}
                      supervision?
                    </Typography>
                    <Typography>
                      {viewerIsUser
                        ? `You will remain matched with your ${supervisorsText}, but won’t be able to book new sessions until you resume your supervision.`
                        : `${userFirstName} will remain matched with their ${supervisorsText}, but won’t be able to book new sessions until they resume their supervision.`}
                    </Typography>
                    <Typography>
                      {viewerIsUser
                        ? `We will inform your ${supervisorsText} that your supervision has been paused.`
                        : `We will inform ${userFirstName}’s ${supervisorsText} that their supervision has been paused.`}
                    </Typography>
                  </>
                )}

                {step === SUBMIT_STEP && (
                  <>
                    <Typography variant="h3">
                      Would you like to provide a reason for pausing?
                    </Typography>
                    <Typography>
                      {viewerIsUser
                        ? `We’ll share this reason with your ${supervisorsText}.`
                        : `We’ll share this reason with ${userFirstName}’s ${supervisorsText}.`}{' '}
                      Providing a reason is optional!
                    </Typography>
                  </>
                )}

                {step === SUCCESS_STEP && (
                  <>
                    <Typography variant="h3">
                      {viewerIsUser ? `Your` : `${userFirstName}’s`} supervision is now paused
                      {values.supervisionResumesOn && (
                        <>
                          <span> until </span>
                          <Typography variant="h3" component="span" color="primary">
                            {values.supervisionResumesOn.toLocaleString(DateTime.DATE_HUGE)}
                          </Typography>
                        </>
                      )}
                      .
                    </Typography>
                    <Typography>{getSuccessText(values.supervisionResumesOn)}</Typography>
                  </>
                )}

                {/* FORM FIELDS */}
                {step === INITIAL_STEP && (
                  <>
                    <MoCheckboxField
                      name="hasResumesOnDate"
                      label="Automatically resume supervision"
                      onChange={() => {
                        if (values.supervisionResumesOn) {
                          form.change('supervisionResumesOn', undefined);
                        }
                      }}
                    />
                    <MoPersistentTooltip
                      placement="top-start"
                      titleContent={
                        <>
                          {viewerIsUser
                            ? `If you provide a date, we will automatically resume your supervision and notify you and your ${supervisorsText} on that date. You may still manually resume supervision at any time.`
                            : `If you provide a date, we will automatically resume ${userFirstName}’s supervision and notify them and their ${supervisorsText} on that date. Supervision may still be manually resumed at any time.`}
                        </>
                      }
                    >
                      {({ toggleTooltip }) => (
                        <IconButton onClick={toggleTooltip}>
                          <InfoOutlined />
                        </IconButton>
                      )}
                    </MoPersistentTooltip>
                    {values.hasResumesOnDate && (
                      <MoDatePickerField
                        name="supervisionResumesOn"
                        label="Resume supervision on"
                        minDate={DateTime.now().plus({ days: 1 })}
                      />
                    )}
                  </>
                )}

                {step === SUBMIT_STEP && (
                  <MoTextInputField
                    name="supervisionPausedReasonText"
                    label="Reason for pausing (optional)"
                  />
                )}

                <Box display="flex" flexDirection="column" gap={1}>
                  {step !== SUCCESS_STEP && (
                    <Typography variant="body2">
                      Note: You can manually resume supervision at any time.
                    </Typography>
                  )}

                  <MoSubmitButton disabled={disabled}>
                    {step === SUBMIT_STEP ? 'Pause supervision' : 'Continue'}
                  </MoSubmitButton>

                  {step !== SUCCESS_STEP && (
                    <MoButton disabled={disabled} variant="outlined" onClick={onClose}>
                      Cancel
                    </MoButton>
                  )}
                </Box>
              </MoSpacing>
            </form>
          );
        }}
      />
    </MoModal>
  );
};

export default PauseSupervisionModal;
